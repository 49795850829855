// import logo from './logo.svg';
import "./App.css";
import Content from "./components/testcomponents/Content";

function App() {
  return (
    <>
      <div>
        <a href="/">therr.io</a>
      </div>

      <Content />
    </>
  );
}

export default App;
