import React, { ReactNode, useEffect, useState } from "react";
import FileRow from "../FileRow/FileRow";
import styled from "styled-components";

const FileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;
const FileRowWrapper = styled.div`
  width: 80%;
`;

export default function Content(props) {
  const [dd, setDd] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  function contentString(s) {
    s = s.substring(1);
    if (s.charAt(s.length - 1) === "/") {
      s = s.substring(0, s.length - 1);
    }
    return s;
  }
  const changeHandler = (event) => {
    let allFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
      allFiles.push(event.target.files[i]);
    }
    // setSelectedFile(event.target.files[0]);
    setSelectedFile(allFiles);
    setIsFilePicked(true);
  };
  const handleSubmission = () => {};

  useEffect(() => {
    const queryString = window.location.pathname;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // prettier-ignore
      body: JSON.stringify({ "content": contentString(queryString) }),
    };
    fetch(
      "https://us-central1-therr-io.cloudfunctions.net/getcontent",
      requestOptions
    )
      .then((response) => response.text())
      .then((data) => {
        setDd(data);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  });
  function listAllFiles(theFiles) {
    let a = [ReactNode];
    var options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    theFiles.forEach((element) => {
      a.push(
        <FileRow
          name={element.name}
          date={element.lastModifiedDate.toLocaleDateString("en-GB", options)}
          size={element.size}
        />
      );
    });
    return a;
  }

  return (
    <div>
      <input
        type="file"
        name="file"
        onChange={changeHandler}
        webkitdirectory
        mozdirectory
        directory
        multiple
      />
      {
        isFilePicked ? (
          <FileContainer>
            <FileRowWrapper>
              {/* <p>Filename: {selectedFile.length}</p> */}
              {listAllFiles(selectedFile)}
              {/* <p>Filetype: {selectedFile.type}</p> */}
              {/* <p>Size in bytes: {selectedFile.size}</p> */}
              {/* <p> */}
              {/* lastModifiedDate:{" "} */}
              {/* {selectedFile.lastModifiedDate.toLocaleDateString()} */}
              {/* </p> */}
            </FileRowWrapper>
          </FileContainer>
        ) : null
        // <p>Select a file to show details</p>
      }
      <div>{/* <button onClick={handleSubmission}>Submit</button> */}</div>
    </div>
  );
}
