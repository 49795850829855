import React from "react";
import styled from "styled-components";

const StyledFileRow = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 1rem 0rem;
  display: flex;
  justify-content: space-between;
`;
export default function FileRow(props) {
  return (
    <StyledFileRow>
      <span>{props.name}</span>
      <span>{props.date}</span>
      <span>{props.size}</span>
      <a href="#">download</a>
    </StyledFileRow>
  );
}
